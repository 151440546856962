<template>
    <!--Checkout-->
    <div class="card-layout checkout">
        <h2 class="title">Checkout</h2>
        <div class="card shadow-sm">
            <div class="card-body p-0">
                <slot v-if="$store.state.customerInfo!=null">
                    <div class="form left">
                        <form action="" class="row">
                            <div class="form-group col-lg-12">
                                <label for="Name">Name</label>
                                <!-- <input type="text" class="form-control shadow-none" placeholder="type your name"> -->
                                <label class="form-control shadow-none">{{name}}</label>
                            </div>
                            <div class="form-group col-lg-12">
                                <label for="Phone">Phone</label>
                               <!--  <input type="text" class="form-control shadow-none" placeholder="type your phone"> -->
                               <label class="form-control shadow-none">{{phone}}</label>
                            </div>
                            <!-- <div class="form-group col-lg-12">
                                <label for="Email">Email</label>
                                <input type="text" class="form-control shadow-none" placeholder="type your email">
                               <label class="form-control shadow-none">{{name}}</label>
                            </div> -->

                            <div class="form-group col-lg-6">
                                <label for="District">District</label>
                              <!--   <input type="text" class="form-control shadow-none" placeholder="District"> -->
                              <label class="form-control shadow-none">{{districtName}}</label>
                            </div>

                            <div class="form-group col-lg-6">
                                <label for="Thana">Upazila</label>
                                <!-- <input type="text" class="form-control shadow-none" placeholder="Thana"> -->
                                <label class="form-control shadow-none">{{upazilaName}}</label>
                            </div>

                            <div class="form-group col-lg-12">
                                <label for="Address">Address</label>
                                <textarea class="form-control shadow-none" placeholder="house no / block / road / area / district" rows="3" readonly>{{address}}</textarea>
                                  <!-- <label class="form-control" rows="3">{{address}}</label> -->
                            </div>
                            
                            
                            <div class="form-group col-lg-12">
                                <label for="Note">Note</label>
                                <textarea class="form-control shadow-none" placeholder="type your note" rows="5" v-model="note"></textarea>
                            </div>
                        </form>
                       <!--  <button type="button" class="btn btn-primary shadow-none"><i class="mdi mdi-plus pr-1"></i> Add Another Delivery Address</button> -->
                       <slot v-if="!newAddressFlag">
                          <!-- <a href="#" class="btn btn-info btn-sm" @click="newAddressFlag=true;deliveryAddressForm=false;">
                            <i class="fa fa-plus"></i> Add New Address
                          </a> -->
                          <slot v-if="addressList.length>0">
                            <a href="#" class="btn btn-warning btn-sm " data-toggle="modal" data-bs-toggle="modal" data-bs-target="#addressModal" v-on:click="getAreaList()">
                              <i class="fa fa-plus"></i> Add Another Delivery Address
                            </a>
                          </slot>
                          <slot v-else>
                               <a href="#" class="btn btn-warning btn-sm text-light" data-bs-toggle="modal" data-bs-target="#addressModal" v-on:click="getAreaList()">
                              <i class="fa fa-plus"></i> Add New Delivery Address
                            </a>
                          </slot>
                            <br>
                      </slot>
                    </div>
                    <div class="info right">
                        <h3>Bill Info</h3>
                        <table class="table table-borderless mb-5">
                            <tr>
                                <th >Voucher Code</th>
                                <td>:</td>
                                <td>
                                  <div class="form-group">
                                    <input type="text" class="form-control shadow-none" v-model="voucher"  placeholder="Enter Voucher Code" v-on:input="getVoucherDiscount()">
                                  </div>
                                </td>
                            </tr>
                            <tr>
                                <th >Voucher Discount</th>
                                <td>:</td>
                                <td>৳ {{promoDiscount}}</td>
                            </tr>
                            <tr>
                                <th>Total Price</th>
                                <td>:</td>
                                <td>৳ {{totalPrice}}</td>
                            </tr>
                            <tr>
                                <th>Total Discount</th>
                                <td>:</td>
                                <td>৳ {{(totalDiscount)}}</td>
                            </tr>
                            <tr>
                                <th>Delivery Charge</th>
                                <td>:</td>
                                <td>৳ {{deliveryCharge}}</td>
                            </tr>
                            <tr>
                                <th>Delivery Charge Discount</th>
                                <td>:</td>
                                <td>৳ {{deliveryChargeDiscount}}</td>
                            </tr>
                            <tr class="border-top">
                                <th><h4>Grand Total</h4></th>
                                <td>:</td>
                                <td>৳ {{grandTotal}}</td>
                            </tr>
                        </table>
                        <h5>Choose Your Payment Method</h5>
                        <div class="payment-method mb-5">
                            <div class="form-check">
                                <input class="form-check-input" type="radio"  name="payMethod" v-model="payMethod" value="1" id="onlinePayment">
                                <label class="form-check-label" for="onlinePayment">
                                    <!-- Online Payment -->
                                    <img :src="payment" height="30" alt="">
                                </label>
                            </div>
                            
                            <div class="form-check" v-if="insideDhaka==1">
                                <input class="form-check-input" type="radio" name="payMethod" v-model="payMethod" value="2" id="cashPayment">
                                <label class="form-check-label" for="cashPayment">
                                    Cash On Delivery
                                </label>
                            </div>
                            <div class="form-check">
                                <input class="form-check-input" type="radio" name="payMethod" v-model="payMethod" value="3" id="bkashPayment" >
                                <label class="form-check-label" for="bkashPayment">
                                    Pay Later Within 48 Hours.
                                </label>
                            </div>
                        </div>
                        <div class="confirm text-center mt-5">
                           <!--  <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#confirm">Confirm Order</button> -->
                            <button type="button" class="btn btn-primary" :disabled="$store.state.cartInfos.totalItem<1 || countDown>0" v-on:click="orderPlaced">Confirm Order</button>
                        </div>
                    </div>
                </slot>
            </div>
           
        </div>
      <!--   <Confirm/> -->
     <!--    <Address/> -->

     <!-- Address Modal -->

      <div class="modal fade" id="addressModal" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="addressModalLabel" aria-hidden="true">
      <div class="modal-dialog modal-xl  modal-dialog-centered fadeIn animated">
        <div class="modal-content p-1">
          <div class="modal-header border-0">
            <h5 class="modal-title" id="addressModalLabel">My Addresses</h5>
            <button type="button" class="close" data-bs-dismiss="modal" aria-label="Close">
            <span class="mdi mdi-close"></span>
            </button>
          </div>
          <div class="modal-body">
              <div class="row">
                <slot v-for="(addressInfo,addressIndex) in addressList"> 
                  <div class="col-lg-4" :key="addressIndex">
                    <div class="card shadow-sm p-3 mb-3">
                      <strong class="mb-3">Address 1</strong> 
                      <table class="table">
                        <tr>
                          <th>Name:</th>
                          <td>{{addressInfo.name}}</td>
                        </tr> 
                        <tr>
                          <th>Phone:</th>
                          <td>{{addressInfo.phone}}</td>
                        </tr> 
                        <tr>
                          <th>Address:</th>
                          <td>{{addressInfo.address}}</td>
                        </tr> 
                        <tr>
                          <td colspan="2">
                               <slot v-if="addressInfo.upazila_info!=null">
                                  {{addressInfo.upazila_info.name}}
                                  <slot v-if="addressInfo.upazila_info.district_info">
                                    , {{addressInfo.upazila_info.district_info.name}}
                                  </slot>
                                </slot>
                          </td>
                        </tr>
                        <tr>
                          <td colspan="2" class="text-right">
                            <a href="#"  v-on:click="selectCustomerAddress(addressInfo.id)" data-bs-dismiss="modal" class="btn bg-info btn-sm">Use This</a>
                          </td>
                        </tr>
                      </table>
                    </div>
                  </div>
                </slot>
              </div>
              <div class="row">
                <div class="col-lg-12">
                  <div class="card shadow-sm p-3 mb-3">
                    <strong class="mb-3">New Address</strong> 
                    <table class="table">
                      <tr>
                        <th width="20%">Name:</th>
                        <td>
                            <div class="form-group">
                              <input type="text" v-model="newName" placeholder="Enter Your Name" class="form-control shadow-none">
                            </div>
                        </td>
                      </tr>
                      <tr>
                        <th>Phone:</th>
                        <td>
                          <div class="form-group">
                            <input type="number" v-model="newPhone" placeholder="Enter Your Phone No." class="form-control shadow-none">
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>District:</th>
                        <td>
                         <!--  <v-select :options="districtList" :reduce="districtList => districtList.id" label="name" v-model="newDistrict" placeholder="Select A District">
                             <template v-slot:option="option">
                                   {{ option.name }}
                              </template>
                            </v-select> -->
                            <div class="form-group">
                              <select v-model="newDistrict" class="form-control shadow-none" placeholder="Select A District">
                                <slot v-for="districtInfo in districtList">
                                  <option :value="districtInfo.id">{{districtInfo.name}}</option>
                                </slot>
                              </select>
                            </div>
                        </td>
                      </tr>
                       <tr>
                        <th>Area:</th>
                        <td>
                          <div class="form-group">
                            <select v-model="newUpazila" class="form-control shadow-none" placeholder="Select An Area">
                              <slot v-for="upazilaInfo in upazilaList">
                                <slot v-if="upazilaInfo.district==newDistrict">
                                  <option :value="upazilaInfo.id">{{upazilaInfo.name}}</option>
                                </slot>
                              </slot>
                            </select>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <th>Address:</th>
                        <td>
                          <div class="form-group">
                            <textarea class="form-control shadow-none" placeholder="Write Address Here" rows="4" v-model="newAddress"></textarea>
                          </div>
                        </td>
                      </tr>
                      <tr>
                        <td colspan="2" class="text-right">
                          <a href="#" class="btn bg-primary text-light btn-sm" @click="addNewAddress()">
                            Use New  Address
                          </a>
                        </td>
                      </tr>
                    </table>
                  </div>
                </div>
              </div>
          </div>
        </div>
      </div>
    </div>
<!-- /Address Modal --> 
    </div>
    <!--/Checkout-->
</template>

<script>
// import Confirm from './elements/Confirm';
// import Address from './elements/Address';
export default {
    components: {
        // Confirm,
        // Address,
    },
    data() {
        return {
          customerPhone:this.$store.state.customerPhone,
          token:this.$store.state.apiToken,
          remember:this.$store.state.rememberToken,
          customerPhone:this.$store.state.customerPhone,
          payment: require('../assets/img/payment.png'),
          // payment: null,
          disabled:false,
          addressList:[],
          newAddressFlag:false,
          upazilaList:null,
          districtList:null,
          voucher:null,
         
          payMethod:null,
          deliveryAddressForm:false,
          errMsgFlag:false,
          msgFlag:false,
          msg:null,
          errMsg:null,
          name:null,
          phone:null,
          address:null,
          upazila:null,
          district:null,
          deliveryAddressId:null,
          upazilaName:null,
          districtName:null,
          newName:null,
          newPhone:null,
          newAddress:null,
          newUpazila:null,
          newDistrict:null,
          insideDhaka:0,
          bkashPayment:null,
          onlinePayment:null,
          cashPayment:null,
          payMethod:null,
          areaId:null,
          note:null,
          invoiceDiscount:0,
          promoDiscount:0,
          productDiscount:0,
          totalPrice:0,
          grandTotal:0,
          deliveryCharge:0,
          totalDiscount:0,
          countDown:0,
          isPreOrder:0,
          deliveryChargeDiscount:0,
        };
  },
  beforeMount() {
    this.$store.commit("minimizeCartView");
    // this.customerAuthCheck();
    // this.loadCustomerAddress();
    // this.getAreaList();
     // this.getCartInfo();
    // this.getCustomerLastAddress();
  },
  mounted(){
    this.customerAuthCheck();
    this.loadCustomerAddress();
    // this.getAreaList();
    this.getCartInfo();
    this.getCustomerLastAddress();
    // this.voucher=this.$store.state.cartInfos.promoCode;
  },
  methods: {
    async getDeliveryChargeDiscount(){

      let formData=new FormData();

     let cartInfos = this.$store.state.cartInfos;

     let flag=false;
    
        cartInfos.forEach(cartInfo => {
          if(cartInfo.isFreeProduct != true ) {
            // let quantity = parseFloat(cartInfo.quantity) + 1;
            formData.append("productId[]", cartInfo.productId);
            formData.append("quantity[]", cartInfo.quantity);
            formData.append("isFreeProduct[]", false);
            formData.append("colorCode[]", cartInfo.colorId);
            formData.append("sizeCode[]", cartInfo.sizeId);
            flag = true;
          }
        });

      formData.append("areaId",this.areaId);
      formData.append("deliveryCharge",this.deliveryCharge);

      await this.$http.post('get/delivery/charge/discount',formData)
             .then(response=>{
                if(response.data.msgFlag==true)
                  this.deliveryChargeDiscount=response.data.deliveryChargeDiscount;
             })
             .catch(error=>{console.dir(error)})
             .finally();
      this.getPriceInfo();
    },
    getCustomerLastAddress(){
      if(this.$store.state.customerInfo!=null){
        let customerInfo=this.$store.state.customerInfo;
        let formData=new FormData();
        formData.append("phone",this.customerPhone);
        formData.append("token",this.token);
        formData.append("remember",this.remember);

        this.$http.post("get/customer/last/address",formData)
         .then(response=>{
            if(response.data.msgFlag){
              this.deliveryAddressForm=true;
              this.selectCustomerAddress(response.data.lastAddressId);
            }
            else{
              this.deliveryAddressForm=false;
            }
         }).
         catch(error=>{console.dir(error)})
         .finally();
       }
    },
    getVoucherDiscount(){
      let customerInfo=this.$store.state.customerInfo;
        let formData=new FormData();
        formData.append("promoCode",this.voucher);
        formData.append("phone",this.customerPhone);
        formData.append("token",this.token);
        formData.append("remember",this.remember);

        let cartInfos = this.$store.state.cartInfos;

        let flag=false;
      
            cartInfos.forEach(cartInfo => {
            if(cartInfo.isFreeProduct != true ) {
              let quantity = parseFloat(cartInfo.quantity) + 1;
              formData.append("productId[]", cartInfo.productId);
              formData.append("quantity[]", cartInfo.quantity);
              formData.append("isFreeProduct[]", false);
              formData.append("colorCode[]", cartInfo.colorId);
              formData.append("sizeCode[]", cartInfo.sizeId);
              flag = true;
            }
          });

          let config = {
                 headers: {
                    'Content-Type': 'multipart/form-data'
                }   
            }; 
        

        this.$http.post("get/promo/discount",formData,config)
         .then(response=>{
            if(response.data.msgFlag==true){
              this.$store.commit("storeCartInfos",response.data.cartInfos);
              this.$store.commit("storePriceInfos",response.data);
              this.getPriceInfo();
            }
         })
         .catch(error=>{console.dir(error)})
         .finally();
         this.getPriceInfo();
    },
    resetOrderForm(){
        this.name=null; 
        this.phone=null; 
        this.address=null;  
        this.deliveryAddressId=null; 
        this.onlinePayment=null; 
        this.cashPayment=null;  
        this.bkashPayment=null;
        this.note=null;
        this.payMethod=null;
    },
    orderFormValidation(){
      if(this.name!=null && this.phone!=null && this.address!=null && this.deliveryAddressId!=null){
         if(this.payMethod!=null){
            return true;
         }
         else{
              this.errMsg="Please Select A Payment Method.";
              this.errMsgFlag=true;
              this.$toaster.warning(this.errMsg);
              return false;
         }
      }
      else{
          this.errMsg="Please Select An Address or Add New Address.";
          this.errMsgFlag=true;
          this.$toaster.warning(this.errMsg);
          return false;
      }
    },
    sendOrderSms(orderId){
      let formData=new FormData();
      formData.append("dataId",orderId);
      this.$http.post("sent/order/sms",formData)
       .then(response=>{
       })
       .catch(error=>{console.dir(error)})
       .finally();
    },
    countDownTimer() {
                if(this.countDown > 0) {
                  if(this.countDown%10==0)
                    this.$toaster.warning("Your Order Is Processing.Please Wait.");
                    setTimeout(() => {
                        this.countDown -= 1
                        this.countDownTimer()
                    }, 1000)
                }
         },
    orderPlaced(){
      if(this.orderFormValidation()){
          this.countDown=20;
          this.countDownTimer();
          let formData=new FormData();
          formData.append("name",this.name);
          // formData.append("phone",this.phone);
          formData.append("address",this.address);
          formData.append("addressId",this.deliveryAddressId);
          formData.append("areaId",this.areaId);
          formData.append("onlinePayment",this.onlinePayment);
          formData.append("bkashPayment",this.bkashPayment);
          formData.append("cashPayment",this.cashPayment);
          formData.append("payMethod",this.payMethod);
          formData.append("deliveryCharge",(this.deliveryCharge-this.deliveryChargeDiscount));
          formData.append("isPreOrder",this.isPreOrder);
          if(this.note!=null)
            formData.append("orderNote",this.note);

          let customerInfo = this.$store.state.customerInfo;

          formData.append("phone",this.customerPhone);
          formData.append("token",this.token);
          formData.append("remember",this.remember);
          formData.append("promoCode",this.voucher);

          let cartInfos = this.$store.state.cartInfos;

          let flag=false;
      
            cartInfos.forEach(cartInfo => {
            if(cartInfo.isFreeProduct != true ) {
              // let quantity = parseFloat(cartInfo.quantity) + 1;
              formData.append("productId[]", cartInfo.productId);
              formData.append("quantity[]", cartInfo.quantity);
              formData.append("isFreeProduct[]", false);
              formData.append("colorCode[]", cartInfo.colorId);
              formData.append("sizeCode[]", cartInfo.sizeId);
              flag = true;
            }
          });

       
        this.$http.post("order/placed",formData)
         .then(response=>{
            if(response.data.msgFlag==true && response.data.isOrderPlaced==true){
              this.countDown=0;
              let orderId=response.data.orderId;
              if(response.data.isOnlinePayment==true){
                this.countDown=0;
                this.sendOrderSms(orderId);
                 window.open(this.$store.state.onlinePaymentUrl+orderId+"/online/payment","_self");
              }
              else{
                this.countDown=0;
                this.sendOrderSms(orderId);
                this.$router.push({name:'Order.Success',params:{orderId:orderId}});
              }
            }
         })
         .catch(error=>{console.dir(error)})
         .finally();
       }
    },
    updatePaymentMethod(status){
      
      // if(status==1){
      //   this.onlinePayment=2;
      //   this.bkashPayment=0;
      //   this.cashPayment=0;
      // }
      // if(status==2){
      //  this.onlinePayment=0;
      //   this.bkashPayment=0;
      //   this.cashPayment=1;
      // }
      // if(status==3){
      //   this.onlinePayment=0;
      //   this.bkashPayment=2;
      //   this.cashPayment=0;
      // }
      // console.dir(this.onlinePayment);
    },
    deleteAddress(addressId){
        this.errMsgFlag=false;
        this.msgFlag=false;
        this.$http.get("customer/address/delete",{params:{dataId:addressId}})
          .then(response=>{
              this.errMsgFlag=response.data.errMsgFlag;
              this.msgFlag=response.data.msgFlag;
              this.errMsg=response.data.errMsg;
              this.msg=response.data.msg;
              if(response.data.errMsgFlag)
                this.$toaster.warning(this.errMsg);
              if(response.data.msgFlag)
                this.$toaster.success(this.msg);
              this.loadCustomerAddress();
          })
          .catch(error=>{console.dir(error)})
          .finally();
      },
    isValidPhoneNumber(){
      let pattern=/(^(\+88|0088)?(01){1}[3456789]{1}(\d){8})$/;
        let flag=pattern.test(this.newPhone);
        if(flag)
          return true;
        else
          return false;
    },
    newAddressFormValidation(){
      if(this.newName!=null && this.newPhone!=null && this.newAddress!=null && this.newUpazila!=null && this.newDistrict!=null){
          if(this.isValidPhoneNumber()){
            return true;
          }
          else{
            this.errMsg="Please Enter Valid Bangladeshi Number.";
            this.errMsgFlag=true;
            this.$toaster.warning(this.errMsg);
            return false;
          }
      }
      else{
          if(this.newName==null){
            this.errMsg="Please Enter Your Name";
            this.$toaster.warning(this.errMsg);
          }
          if(this.newPhone==null){
            this.errMsg="Please Enter Phone Number.";
            this.$toaster.warning(this.errMsg);
          }
          if(this.newAddress==null){
            this.errMsg="Please Write Address.";
            this.$toaster.warning(this.errMsg);
          }
          if(this.newDistrict==null){
            this.errMsg="Please Select Your District.";
            this.$toaster.warning(this.errMsg);
          }
          if(this.newUpazila==null){
            this.errMsg="Please Select Your Upazila.";
            this.$toaster.warning(this.errMsg);
          }
          this.errMsgFlag=true;
          return false;
      }
    },
    addNewAddress(){
      if(this.newAddressFormValidation()){
         let customerInfo = this.$store.state.customerInfo;
        let formData=new FormData();
        formData.append("customerPhone",customerInfo.phone);
        formData.append("token",customerInfo.phone);
        formData.append("remember",customerInfo.remember);
        formData.append("name",this.newName);
        formData.append("phone",this.newPhone);
        formData.append("address",this.newAddress);
        formData.append("upazila",this.newUpazila);
        formData.append("district",this.newDistrict);
       
        this.$http.post("add/new/address",formData)
         .then(response=>{
            if(response.data.msgFlag==true){
              this.selectCustomerAddress(response.data.dataId);
              this.resetNewAddressForm();
              this.loadCustomerAddress();
            }
            else{
              this.errMsgFlag=response.data.errMsgFlag;
              this.errMsg=response.data.errMsg;
              this.$toaster.warning(this.errMsg);
            }
         })
         .catch(error=>{console.dir(error)})
         .finally();
      }
    },
    async selectCustomerAddress(addressId){
      
      let customerInfo=this.$store.state.customerInfo;
      if(customerInfo!=null){
          let formData=new FormData();
          formData.append("phone",this.customerPhone);
          formData.append("token",this.token);
          formData.append("remember",this.remember);
          formData.append("dataId",addressId);

        await this.$http.post("select/customer/address",formData)
           .then(response=>{
              if(response.data.msgFlag==true){
                this.newAddressFlag=false;
                this.deliveryAddressForm=true;
                this.name=response.data.dataInfo.name;
                this.phone=response.data.dataInfo.phone;
                this.address=response.data.dataInfo.address;
                this.deliveryAddressId=response.data.dataInfo.id;
                this.newAddressFlag=false;
                this.deliveryAddressForm=true;
              
                if(response.data.dataInfo!=null){
                  if(response.data.dataInfo.upazila_info!=null){
                    this.upazilaName=response.data.dataInfo.upazila_info.name;
                    this.deliveryCharge=response.data.dataInfo.upazila_info.deliveryCharge;
                    this.insideDhaka=response.data.dataInfo.upazila_info.insideDhaka;
                    this.areaId=response.data.dataInfo.upazila_info.id;
                    if(response.data.dataInfo.upazila_info.district_info!=null){
                      this.districtName=response.data.dataInfo.upazila_info.district_info.name;
                      
                    }
                  }
                }
                
              }
              else
              {
                this.deliveryAddressForm=false;
                this.errMsgFlag=response.data.errMsgFlag;
                this.errMsg=response.data.errMsg;
                this.$toaster.warning(this.errMsg);
              }
           })
           .catch(error=>{console.dir(error)})
           .finally();
           this.getDeliveryChargeDiscount();
           this.getPriceInfo();
     }
    },
    getCartInfo(){
            
            let cartInfos = this.$store.state.cartInfos;

            let formData=new FormData();

            let flag=false;
      
            cartInfos.forEach(cartInfo => {
            if(cartInfo.isFreeProduct != true ) {
              let quantity = parseFloat(cartInfo.quantity) + 1;
              formData.append("productId[]", cartInfo.productId);
              formData.append("quantity[]", cartInfo.quantity);
              formData.append("isFreeProduct[]", false);
              formData.append("colorCode[]", cartInfo.colorId);
              formData.append("sizeCode[]", cartInfo.sizeId);
              formData.append("addedToCart[]", false);
              flag = true;
            }
          });

          let config = {
                 headers: {
                    'Content-Type': 'multipart/form-data'
                }   
            }; 
        

           this.$http.post("product/add/to/cart",formData,config)
            .then(response=>{
              if(response.data.msgFlag==true){
                // console.dir(response.data);
                 this.$store.commit("storeCartInfos",response.data.cartInfos);
                 this.$store.commit("storePriceInfos",response.data);
                 this.getPriceInfo();
              }
              else{
                 // this.$toaster.warning(response.data.errMsg);
              }
            })
            .catch(error=>{console.dir(error)})
            .finally();
    },
    resetNewAddressForm(){
      this.newName=null;
      this.newPhone=null;
      this.newAddress=null;
      this.newUpazila=null;
      this.newDistrict=null;
    },
    loadCustomerAddress(){
      if(this.$store.state.customerInfo!=null){
          let customerInfo=this.$store.state.customerInfo;
            let formData=new FormData();
            formData.append("phone",this.customerPhone);
            formData.append("token",this.token);
            formData.append("remember",this.remember);

          this.errMsgFlag=false;
          this.msgFlag=false;
          this.$http.post("customer/address",formData)
          .then(response=>{
          if(response.data.msgFlag==true)
          this.addressList=response.data.dataList;
          })
          .catch(error=>{console.dir()})
          .finally();
          this.getPriceInfo();
      }
    },
    customerAuthCheck(){
        if(this.$store.state.customerInfo!=null){
            let customerInfo=this.$store.state.customerInfo;
            let formData=new FormData();
            formData.append("phone",this.customerPhone);
            formData.append("token",this.token);
            formData.append("remember",this.remember);

            this.$http.post("auth/check",formData)
            .then(response=>{
              if(response.data.msgFlag==true){

                
              }
              else{
                this.$store.commit("storeCustomerInfo",null);
                this.$router.push({name:'signinfor.checkout'});
              }
            })
            .catch(error=>{console.dir(error)})
            .finally();
        }
        else{
           this.$router.push({name:'signinfor.checkout'});
        }
    },
    getAreaList(){
        this.$http.get("all/area/list")
         .then(response=>{
            if(response.data.msgFlag==true){
              this.upazilaList=response.data.upazilaList; 
              this.districtList=response.data.districtList; 
            }
         })
         .catch(error=>{console.dir(error)})
         .finally();
      },
      getPriceInfo(){
          this.invoiceDiscount=this.$store.state.priceInfos.invoiceDiscount;       
          this.promoDiscount=this.$store.state.priceInfos.promoDiscount;
          this.productDiscount=this.$store.state.priceInfos.productDiscount;
          this.totalPrice=this.$store.state.priceInfos.totalPrice;
          this.totalDiscount=this.invoiceDiscount+this.promoDiscount+this.productDiscount;
          this.grandTotal=((parseInt(this.totalPrice)+(parseInt(this.deliveryCharge)-parseInt(this.deliveryChargeDiscount)))-this.totalDiscount);
          // this.deliveryCharge=this.$store.state.cartInfos.invoiceDiscount;
      },
       signinFunction(){
            if(this.formValidation()){
                let formData=new FormData();
                formData.append("userName",this.userName);
                formData.append("password",this.password);
                axios.post("website/customer/signin",formData)
                 .then(response=>{
                    if(response.data.msgFlag==true){
                        this.$toaster.success(response.data.msg);
                        this.$store.commit('storeCustomerInfo',response.data.customerInfo);
                        this.userName=null;
                        this.password=null;
                        this.$router.push({name:'checkout'});
                    }
                    else{
                        this.errMsgFlagA=response.data.errMsgFlag;
                        this.errMsg=response.data.errMsg;
                        this.$toaster.warning(this.errMsgA);
                    }
                 })
                 .catch(error=>{console.dir(error)})
                 .finally();
            }
        },
        formSubmitPrevent(e){
             e.preventDefault();
        },
        formValidation(){
            if(this.userName!=null && this.password!=null){
                return true;
            }
            else{
                if(this.userName==null){
                    this.errMsg="Please Enter Your Phone  First.";
                    this.$toaster.warning(this.errMsg);
                }
                if(this.password==null){
                    this.errMsg="Please Enter Password.";
                    this.$toaster.warning(this.errMsg);
                }
                this.errMsgFlagA=true;
                return false;
            }
        },
        resetRegistrationForm(){
            this.name=null;
            this.phone=null;
            this.email=null;
            this.password=null;
            this.conPassword=null;
            this.otp=null;
            this.otpForm=false;
            this.signUpForm=true;
        },
        countDownTimer() {
                if(this.countDown > 0) {
                    setTimeout(() => {
                        this.countDown -= 1
                        this.countDownTimer()
                    }, 1000)
                }
         },
        emailValidation() 
        {
            let pattern=/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
            let flag=pattern.test(this.email);
            if(flag)
                return true;
            else
                return false;
        },
       signUpFormValidation(){
        if(this.name!=null  && this.phone!=null && this.newPassword!=null && this.conPassword!=null){
            if(this.phoneNumberValidation() ){
                if(this.newPassword==this.conPassword){
                    return true;
                }
                else{
                    this.errMsg="Please Confirm Password.";
                    this.errMsgFlagB=true;
                    this.$toaster.warning(this.errMsg);
                    
                    return false;
                }
            }
            else{
                if(!this.phoneNumberValidation()){
                    this.errMsg="Please Enter A Valid Bangladeshi Phone Number.";
                    this.$toaster.warning(this.errMsg);
                }
                // if(!this.emailValidation()){
                //  this.errMsg="Please Enter An Valid Email Address.";
                //  this.$toaster.warning(this.errMsg);
                // }
                this.errMsgFlagB=true;
                return false;
            }
        }
        else{
            if(this.name==null){
                this.errMsg="Please Enter Your Name.";
                this.$toaster.warning(this.errMsg);
            }
            // if(this.email==null){
            //  this.errMsg="Please Enter Your Mail Address.";
            //  this.$toaster.warning(this.errMsg);
            // }
            if(this.newPassword==null){
                this.errMsg="Please Enter Password.";
                this.$toaster.warning(this.errMsg);
            }
            if(this.conPassword==null){
                this.errMsg="Please Confirm Password.";
                this.$toaster.warning(this.conPassword);
            }
            this.errMsgFlagB=true;
            return false;
        }
      },
      verifyCustomerInfo(){
        if(this.signUpFormValidation()){
            let formData=new FormData();
            formData.append("name",this.name);
            formData.append("phone",this.phone);
            // formData.append("email",this.email);
            formData.append("password",this.newPassword);
            formData.append("conPassword",this.conPassword);
            formData.append("otp",this.otp);
            this.countDown=180;
            this.countDownTimer();
            axios.post("website/verify/customer/info",formData)
             .then(response=>{
                this.errMsgFlagB=response.data.errMsgFlag;
                this.errMsg=response.data.errMsg;
                this.msgFlagB=response.data.msgFlag;
                this.msg=response.data.msg;
                if(response.data.msgFlag==true){
                    this.$toaster.success(this.msg);
                    this.signUpForm=false;
                    this.otpForm=true;
                    
                }
                if(response.data.errMsgFlag==true){
                    this.$toaster.warning(this.errMsg);
                }
             })
             .catch(error=>{console.dir(error)})
             .finally();
        }
       },
       signUpFunction(){
        if(this.signUpFormValidation()){
            let formData=new FormData();
            formData.append("name",this.name);
            formData.append("phone",this.phone);
            formData.append("email",this.email);
            formData.append("password",this.newPassword);
            formData.append("conPassword",this.conPassword);
            formData.append("otp",this.otp);
            axios.post("website/verify/customer/phone",formData)
             .then(response=>{
                this.errMsgFlagB=response.data.errMsgFlag;
                this.errMsg=response.data.errMsg;
                this.msgFlagB=response.data.msgFlag;
                this.msg=response.data.msg;
                if(response.data.msgFlag==true){
                    this.$toaster.success(this.msg);
                    this.signUpForm=false;
                    this.otpForm=true;
                    if(response.data.loginFlag){
                        this.$store.commit('storeCustomerInfo',response.data.customerInfo);
                        this.$router.push({name:'checkout'});
                        this.resetRegistrationForm();
                    }
                    this.resetRegistrationForm();
                }
                if(response.data.errMsgFlag==true){
                    this.$toaster.warning(this.errMsg);
                }
             })
             .catch(error=>{console.dir(error)})
             .finally();
        }
       },
       phoneNumberValidation()
       {
          let pattern=/(^(\+88|0088)?(01){1}[3456789]{1}(\d){8})$/;
          let flag=pattern.test(this.phone);
          if(flag)
            return true;
          else
            return false;
       },
  }
}
</script>

<style>

</style>